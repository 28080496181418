import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import axios from 'axios';
import { LocalStorageService } from 'application/services';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 0.2,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 403 && !error.response.config.url.includes('/reset-password/confirm')) {
    LocalStorageService.clear()
    window.location.reload()
    window.location.pathname = "/"
  }
  return Promise.reject(error)
})
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();