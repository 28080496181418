import { t } from "i18next";
import * as z from "zod"
const passwordValidation = new RegExp(
	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);
export const setPasswordSchema = z.object({
	password: z
		.string()
		.min(8, t('password-required'))
		.regex(passwordValidation, { message: t('password-format') }),
	confirm_password: z
		.string()
		.min(8, t('password-required'))
		.regex(passwordValidation, { message: t('password-format') })
}).refine((data) => data.password === data.confirm_password, {
	path: ['confirm_password'],
	message: t('password-not-match')
})
export const SigninSchema = z.object({
	email: z
		.string()
		.min(1, { message: t('email-required') })
		.email(t('email-format')),

	password: z
		.string()
		.min(8, { message: t('password-required') })
		.regex(passwordValidation, { message: t('password-format') })
})
export const EmailSchema = z.object({
	email: z
		.string()
		.min(1, { message: t('email-required') })
		.email(t('email-format')),
})