import React from "react";
import { Background } from "./components"
const Layout = (props) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2" data-testid="layout">
            <Background />
            {props.children}
        </div>
    )
}
export default Layout