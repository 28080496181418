import { useNavigate } from 'react-router-dom'
import animationSuccess from '../../assets/img/success.json'
import Lottie from 'react-lottie-player'
import { Button } from '.'
import { t } from 'i18next'
export const Success = () => {
	const navigate = useNavigate()
	return (
		<div className="flex flex-col mt-[20%] items-center gap-10">
			<Lottie
				loop
				play
				animationData={animationSuccess}
				style={{
					height: 175,
					width: 175
				}}
			/>
			<div className='flex flex-col justify-center items-center gap-6 mt-[10%]'>
				<p className='font-semibold'>{t('questions-answered')}</p>
				<Button onClick={() => navigate('/')}>{t('return-to-home')}</Button>
			</div>
		</div>
	)
}