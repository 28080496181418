import axios from "axios";
import { addAxiosDefaultHeaders } from "helpers";
export class AuthService {
	constructor(_baseUrl) {
		this._baseUrl = `${_baseUrl}/auth/v1`
	}
	accessToken(values, token) {
		addAxiosDefaultHeaders(axios, token)
		return axios.post(`${this._baseUrl}/sme/obtain-access-token`, values)
	}
	resetPassword(values) {
		//addAxiosDefaultHeaders(axios, token)
		return axios.post(`${this._baseUrl}/sme/reset-password`, values)
	}
	confirmPassword(values, token) {
		addAxiosDefaultHeaders(axios, token)
		return axios.post(`${this._baseUrl}/sme/reset-password/confirm`, values)
	}
}