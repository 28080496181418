import React from "react";
import { Button, Form, FormControl, FormField, FormItem, FormMessage, Header, Input, useToast } from 'components/ui'
import PasswordIcon from '../../assets/img/password.svg'
import { ServiceProxy } from 'ServiceProxy';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EmailSchema } from "pages/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { t } from 'i18next';
import { showToastError, showToastSuccess } from 'helpers';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import * as Sentry from '@sentry/react';
export const ForgetPassword = () => {
	const navigate = useNavigate()
	const { toast } = useToast()
	const [loading, setLoading] = useState(false)
	const form = useForm({
		defaultValues: {
			email: ''
		},
		resolver: zodResolver(EmailSchema)
	})
	const forgetPassword = (values) => {
		ServiceProxy.AuthService.resetPassword(values)
			.then((res) => {
				setLoading(false)
				showToastSuccess(toast)
			})
			.catch((error) => {
				setLoading(false)
				showToastError(toast)
				Sentry.captureException(error);
			})
	}
	const onSubmit = (values) => {
		setLoading(true)
		forgetPassword(values)
	}
	return (
		<div className='w-full h-full p-[12%]'>
			<Header onClickBack={() => navigate(-1)} />
			<p className='font-bold text-3xl mb-2 text-start'>{t('forget-password')}</p>
			<p className='text-sm text-start text-[#8692A6] mb-5'>{t('forget-password-desc')}</p>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} >
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input className='px-8' placeholder={t('email')} icon={PasswordIcon} type='email'
										value={field.value}
										{...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button className='w-full border rounded-3xl mt-10 mb-3 justify-center' type="submit">{loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />} {t('confirm')}</Button>
				</form>
			</Form>
		</div>
	)
}