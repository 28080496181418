import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../globalization/locales/en.json';
import translationAR from '../globalization/locales/arab.json';
import LanguageDetector from "i18next-browser-languagedetector";
const options = {
  order: ['querystring', 'path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
  lookupQuerystring: 'lang',
}
export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: "ar",
    supportedLngs: ["en", "ar"],
    resources: {
      ar: {
        translation: translationAR
      },
      en: {
        translation: translationEN
      }
    }
  })
export function changeCurrentLanguage(language) {
  i18n.changeLanguage(language);
  if (window.location.pathname === '/' && window.location.search.includes('lang')) {
    window.location.href = `${window.location.pathname}?${new URLSearchParams({ lang: language }).toString()}`
  }
  else {
    window.location.reload()
  }
}
export function getCurrentLanguage() {
  return i18n.language;
}