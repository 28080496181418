export const localStorageKeys = {
  token: "token"
}

export class LocalStorageService {
  static get = (key) => {
    return JSON.parse(
      localStorage.getItem(key) || null
    );
  };

  static set = (key, value) => {
    localStorage.setItem(
      key,
      JSON.stringify(value)
    );
  };

  static delete = (key) => {
    localStorage.removeItem(key);
  };
  static clear = () => {
    localStorage.clear();
  }
}