import { AuthService, LocalStorageService, InsuranceService } from "./services";

export class ServiceProxy {
	AuthService;
	LocalStorageService;
	InsuranceService;
	constructor(_apiUrl) {
		this._apiUrl = _apiUrl;
		this.AuthService = new AuthService(this._apiUrl);
		this.LocalStorageService = new LocalStorageService(this._apiUrl);
		this.InsuranceService = new InsuranceService(this._apiUrl);
	}
}