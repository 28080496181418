import React from "react";
import { Button, Header, Input, InputFile, useToast } from 'components/ui'
import LockIcon from '../../assets/img/lock.svg'
import { useEffect, useRef, useState } from 'react'
import { LocalStorageService, localStorageKeys } from 'application/services'
import { ServiceProxy } from 'ServiceProxy'
import { useNavigate, useLocation } from 'react-router-dom'
import { showToastError } from 'helpers'
import { Loader2 } from 'lucide-react'
import Download from '../../assets/img/download.svg'
import * as Sentry from '@sentry/react'
import { t } from 'i18next'
import { validIssuanceStatus } from 'config/enums'
export const UploadCR = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const location = useLocation();
	const { toast } = useToast()
	const [pagesCount, setPagesCount] = useState(0)
	const [questions, setQuestions] = useState([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(0)
	const [file, setFile] = useState(null)
	const [url, setUrl] = useState('')
	const [text, setText] = useState(null)
	const [submit, setSubmit] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [valid, setValid] = useState(false)
	const [templateLink, setTemplateLink] = useState(null)
	const [answerLink, setAnswerLink] = useState(null)
	const downloadTemplateRef = useRef(null);
	const downloadAnswerRef = useRef(null);
	const isProgrammaticClick = useRef(false);

	const FileRef = useRef(null)
	// eslint-disable-next-line no-useless-escape
	const URLRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	const token = LocalStorageService.get(localStorageKeys.token)
	const clickUploader = () => {
		FileRef.current.click();
	}
	const listQuestions = () => {
		ServiceProxy.InsuranceService.listQuestions(token)
			.then((res) => {
				if (res && res.data && res.data.data) {
					const result = res.data.data
					setValid(validIssuanceStatus.includes(result.issuance_status))
					setPagesCount(result.questions.length)
					setQuestions(result.questions)
					setCurrentPage(0)
					setLoading(false)
				}
			})
			.catch((error) => {
				setLoading(false)
				showToastError(toast)
				Sentry.captureException(error);
			})
	}
	const renderQuestions = (q) => {
		if (q.document_type === 28) {
			return (
				<>
					<Input placeholder={t('valid-link')} onChange={handleInputChangeUrl} value={url} type="url" pattern="https://.*" size="30" disabled={valid === false} />
					{errorMessage && <p className='text-[red] mt-2'>{errorMessage}</p>}
				</>
			)
		}
		else if (q.document_type === 31) {
			return (
				<> <Input placeholder={q.title} onChange={handleInputChangeNumber} value={text} disabled={valid === false} />
					{errorMessage && <p className='text-[red] mt-2'>{errorMessage}</p>}
				</>
			)
		}
		else
			return <InputFile label={q.title} data={q} onChange={onFileChange} file={file} inputRef={FileRef} clickUploader={clickUploader} disabled={valid === false} handleDownloadAnswer={handleDownloadAnswer} answerLink={answerLink} downloadAnswerRef={downloadAnswerRef} />
	}
	const onFileChange = (event) => {
		setFile(event.target.files[0])
	};
	const handleInputChangeUrl = (e) => {
		const value = e.target.value;
		setUrl(value);
		if (isValidURL(value)) {
			setErrorMessage('');
		} else {
			setErrorMessage(t('valid-link'));
		}
	};
	const isValidURL = (str) => {
		var pattern = new RegExp(URLRegex)
		return pattern.test(str);
	};
	const handleInputChangeNumber = (e) => {
		const value = e.target.value;
		setText(value);
		if (isValidNumber(value) || isValidURL(value)) {
			setErrorMessage('');
		} else {
			setErrorMessage(t('valid-number'));
		}
	};
	const isValidNumber = (str) => {
		const regex = /^[0-9]*$/;
		var pattern = new RegExp(regex)
		return pattern.test(str);
	};
	const validateAnswers = () => {
		const q = questions[currentPage]
		if (q?.document_type === 28 && (url)) {
			return true
		}
		else if (q?.document_type === 31 && (text)) {
			return true
		}
		else if (q?.document_type !== 28 && q.document_type !== 31 && (file || q.submitted_answer?.file_name)) {
			return true
		}
		else return false
	}
	const updateItems = (index, newValue) => {
		const updatedArr = questions.map((item, i) =>
			i === index ? { ...item, submitted_answer: { ...item.submitted_answer, string_value: newValue } } : item
		)
		return updatedArr
	};
	const handleNavigation = (q) => {
		const result = updateItems(currentPage, q.document_type === 28 ? url : text)
		if (currentPage < pagesCount - 1) {
			navigate(`/step/${currentPage + 1}`, { state: { page: currentPage + 1, questions: result, pagesCount } })
		}
		else {
			navigate('/success')
		}
	}
	const submitAnswer = () => {
		const q = questions[currentPage]
		if ((file && q.document_type !== 28 && q.document_type !== 31) || (text && q.document_type === 31) || (url && q.document_type === 28)) {
			setSubmit(true)
			let databody = null;
			if (q.document_type === 28 || q.document_type === 31) {
				databody = {
					document_type: q.document_type,
					string_value: q.document_type === 28 ? url : text
				}
				sendAnswersWithText(databody, q)
			}
			else {
				var formData = new FormData();
				formData.append('file', file)
				formData.append('document_type', q.document_type)
				databody = formData
				sendAnswersWithFiles(databody)
			}
		}
		else {
			if (currentPage < pagesCount - 1) {
				navigate(`/step/${currentPage + 1}`, { state: { page: currentPage + 1, questions, pagesCount } })
			}
			else {
				navigate('/success')
			}
		}
	}
	const sendAnswersWithFiles = (data) => {
		ServiceProxy.InsuranceService.answerPolicyQuestionsWithFiles(token, data)
			.then((res) => {
				setSubmit(false)
				if (currentPage < pagesCount - 1) {
					navigate(`/step/${currentPage + 1}`, { state: { page: currentPage + 1, questions, pagesCount } })
				}
				else {
					navigate('/success')
				}
			})
			.catch((error) => {
				setSubmit(false)
				showToastError(toast)
				Sentry.captureException(error);
			})
	}
	const sendAnswersWithText = (data, q) => {
		ServiceProxy.InsuranceService.answerPolicyQuestionsWithText(token, data)
			.then((res) => {
				setSubmit(false)
				handleNavigation(q)
			})
			.catch((error) => {
				setSubmit(false)
				showToastError(toast)
				Sentry.captureException(error);
			})
	}
	const setDefaultValues = (ques, page) => {
		const q = ques[page]
		if (q.submitted_answer?.string_value && q.document_type === 28) {
			setUrl(q.submitted_answer.string_value)
		}
		else if (q.submitted_answer?.string_value && q.document_type === 31) {
			setText(q.submitted_answer.string_value)
		}
	}
	const handleGoBack = () => {
		if (currentPage === 0) {
			navigate('/home')
		}
		else if (currentPage === 1) {
			navigate(`/step`)
		}
		else if (currentPage - 1 <= pagesCount) {
			navigate(`/step/${currentPage - 1}`, { state: { page: currentPage - 1, questions, pagesCount } })
		}
		else {
			navigate('/success')
		}
	};
	const handleDownloadTemplate = (event, id) => {
		if (event?.preventDefault) {
			if (isProgrammaticClick.current) {
				isProgrammaticClick.current = false;
				return;
			}
			event.preventDefault();
			ServiceProxy.InsuranceService.getQuestionTemplate(token, id)
				.then((res) => {
					if (res && res.data && res.data.data) {
						setTemplateLink(res.data.data.file)
						if (downloadTemplateRef.current) {
							downloadTemplateRef.current.href = res.data.data.file;
							isProgrammaticClick.current = true;
							downloadTemplateRef.current.click();
						}
					}
				})
				.catch((error) => {
					setLoading(false)
					showToastError(toast)
					Sentry.captureException(error);
				})
		}
	}
	const handleDownloadAnswer = (event, id) => {
		if (event?.preventDefault) {
			if (isProgrammaticClick.current) {
				isProgrammaticClick.current = false;
				return;
			}
			event.preventDefault();
			ServiceProxy.InsuranceService.getAnswerLink(token, id)
				.then((res) => {
					if (res && res.data && res.data.data) {
						setAnswerLink(res.data.data.file)
						if (downloadAnswerRef.current) {
							downloadAnswerRef.current.href = res.data.data.file;
							isProgrammaticClick.current = true;
							downloadAnswerRef.current.click();
						}
					}
				})
				.catch((error) => {
					setLoading(false)
					showToastError(toast)
					Sentry.captureException(error);
				})
		}
	}
	useEffect(() => {
		setLoading(true)
		if (state && state.page) {
			setFile(null)
			setCurrentPage(state.page)
			setQuestions(state.questions)
			setPagesCount(state.questions.length)
			setDefaultValues(state.questions, state.page)
			setErrorMessage('')
			setTimeout(() => {
				setLoading(false)
			}, 1000);
		}
		else { listQuestions() }
	}, [location]);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className='w-full min-h-screen'>
			<>
				<Header onClickBack={handleGoBack} currentPage={currentPage + 1} questionsNumber={pagesCount} />
				{loading ? <div role="progressbar" className='flex h-[90%] justify-center items-center'><Loader2 className="h-12 w-12 animate-spin" /></div> :
					<div className='md:pl-[15%] md:pr-[22%] px-10 h-[90%] flex flex-col justify-between pb-3'>
						<div>
							<p className='font-bold text-3xl mb-3 mt-16 text-start'>{questions[currentPage].title}</p>
							<p className='text-sm text-start text-[#8692A6] mb-8'>
								{questions[currentPage].description}
							</p>
							{questions[currentPage].has_template &&
								<div className='flex flex-row gap-1 mb-8'>
									<img src={Download} alt='Download' className='object-contain' />
									<a className='text-[#0066FF] text-base font-semibold underline' target='_blank' rel="noreferrer" ref={downloadTemplateRef} download href={`${templateLink || '#'}`} onClick={(e) => handleDownloadTemplate(e, questions[currentPage].id)}>{t('download-application')}</a>
								</div>}
							{renderQuestions(questions[currentPage])}
						</div>
						<div className='flex flex-col-reverse md:flex-col'>
							<Button className={`${validateAnswers() === false ? 'opacity-50' : null} w-full border rounded-3xl my-3 justify-center`} disabled={validateAnswers() === false} onClick={submitAnswer}>{submit && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{t('next')}</Button>
							<div className='flex flex-row justify-center items-center gap-2'>
								<img src={LockIcon} alt='LockIcon' />
								<p className='text-xs font-normal text-[#8692A6]'>{t('info-safely')}</p>
							</div>
						</div>
					</div>
				}
			</>
		</div>
	)
}