import { LocalStorageService, localStorageKeys } from "application/services";
import { NotFound, Success } from "components/ui";
import { ForgetPassword, Home, SetPassword, Signin, UploadCR } from "pages";
import Layout from "pages/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
export const AppRoutes = () => {
	const token = LocalStorageService.get(localStorageKeys.token)
	return (
		<Router>
			<Layout>
				<Routes>
					<Route exact path="/" element={token ? <Home /> : <Signin />} />
					<Route exact path="/step" element={<UploadCR />} />
					<Route exact path="/step/:number" element={<UploadCR />} />
					<Route exact path="/set-password" element={<SetPassword />} />
					<Route exact path="/home" element={<Home />} />
					<Route exact path="/forget-password" element={<ForgetPassword />} />
					<Route exact path="/success" element={<Success />} />
					<Route exact path="*" element={<NotFound />} />
				</Routes>
			</Layout>
		</Router>
	);
};