import React from "react";
import { Button, Form, FormControl, FormField, FormItem, FormMessage, Input, useToast } from 'components/ui'
import EmailIcon from '../../assets/img/mail.svg'
import PasswordIcon from '../../assets/img/password.svg'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SigninSchema } from 'pages/schema'
import { ServiceProxy } from 'ServiceProxy'
import { showToastError } from 'helpers'
import { t } from 'i18next'
import { LocalStorageService, localStorageKeys } from 'application/services'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import * as Sentry from '@sentry/react';
export const Signin = () => {
	const navigate = useNavigate()
	const { toast } = useToast()
	const [loading, setLoading] = useState(false)
	const form = useForm({
		defaultValues: {
			email: '',
			password: ''
		},
		resolver: zodResolver(SigninSchema)
	})
	const token = new URLSearchParams(window.location.search).get("token")
	const accessToken = (values) => {
		ServiceProxy.AuthService.accessToken(values, token)
			.then((res) => {
				setLoading(false)
				LocalStorageService.set(localStorageKeys.token, res.data.token)
				navigate('/home', { replace: true })
				window.location.reload()
			})
			.catch((error) => {
				setLoading(false)
				showToastError(toast)
				Sentry.captureException(error);
			})
	}
	const onSubmit = (values) => {
		setLoading(true)
		accessToken(values)
	}
	return (
		<div className='w-full p-10 md:p-[12%]'>
			<p className='font-bold text-3xl mb-2 text-start'>{t('signin')}</p>
			<p className='text-sm text-start text-[#8692A6] mb-5'>{t('welcome')}</p>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} >
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input className='px-8' placeholder={t('email')} icon={EmailIcon} type='email'
										value={field.value}
										{...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input className='mt-3 px-8' placeholder={t('password')} icon={PasswordIcon} type='password'
										value={field.value}
										{...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<div onClick={() => navigate('/forget-password')} className='text-sm text-end px-1 underline text-[#8692A6] cursor-pointer'>{t('forget-password-ask')}</div>
					<Button className='w-full border rounded-3xl mt-10 mb-3 justify-center'>{loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />} {t('next')}</Button>
				</form>
			</Form>
		</div>
	)
}