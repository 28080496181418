import React from "react";
import { LocalStorageService } from "application/services"
import { t } from "i18next"
import { BadgeCheck, BadgeX } from "lucide-react"
export const showToastError = (toast) => {
	return toast({
		variant: "destructive",
		title: <div className="flex flex-row gap-2"><BadgeX />{t('error_message')}</div>,
		duration: 5000
	})
}
export const showToastTokenError = (toast) => {
	return toast({
		variant: "destructive",
		title: <div className="flex flex-row gap-2"><BadgeX />{t('error_token-message')}</div>,
		duration: 5000
	})
}
export const showToastSuccess = (toast) => {
	return toast({
		title: <div className="flex flex-row gap-2"><BadgeCheck />{t('check-email')}</div>,
		duration: 5000
	})
}
export const handleLogout = () => {
	LocalStorageService.clear()
	window.location.reload()
	window.location.pathname = "/"
}