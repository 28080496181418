import axios from "axios";
import { addAxiosDefaultHeaders } from "helpers";
export class InsuranceService {
	constructor(_baseUrl) {
		this._baseUrl = `${_baseUrl}/insurance/v1`
	}
	listQuestions(token) {
		addAxiosDefaultHeaders(axios, token)
		return axios.get(`${this._baseUrl}/sme-portal/questions`)
	}
	answerPolicyQuestionsWithFiles(token, values) {
		const result = axios({
			method: "post",
			url: `${this._baseUrl}/sme-portal/answers`,
			headers: { "Content-Type": "multipart/form-data", "Authorization": `auth ${token}`, "Content-Language": "AR" },
			transformRequest: (data, headers) => {
				return data;
			},
			data: values,
		})
		return result
	}
	answerPolicyQuestionsWithText(token, values) {
		addAxiosDefaultHeaders(axios, token)
		return axios.post(`${this._baseUrl}/sme-portal/answers`, values)
	}
	getQuestionTemplate(token, id) {
		addAxiosDefaultHeaders(axios, token)
		return axios.get(`${this._baseUrl}/sme-portal/questions/${id}/download`)
	}
	getAnswerLink(token, id) {
		addAxiosDefaultHeaders(axios, token)
		return axios.get(`${this._baseUrl}/sme-portal/answers/${id}/download`)
	}
}