import LogoEN from '../../../../assets/img/logoEN.svg'
import LogoAR from '../../../../assets/img/logoAR.svg'
import Icon from '../../../../assets/img/icon.svg'
import IconOfHome from '../../../../assets/img/iconOfHome.svg'
import EnglishLang from '../../../../assets/img/englishLang.svg'
import ArabicLang from '../../../../assets/img/arabicLang.svg'
import { changeCurrentLanguage, getCurrentLanguage } from 'config/i18n'
import { languageEnum } from 'config/enums'
import { useEffect } from 'react'
import { t } from 'i18next'
import { Button } from 'components/ui'
import { handleLogout } from 'helpers'
import { localStorageKeys, LocalStorageService } from 'application/services'
export const Background = () => {
	const selectedLang = getCurrentLanguage();
	const token = LocalStorageService.get(localStorageKeys.token)
	useEffect(() => {
		if (selectedLang === languageEnum.Arabic) {
			document.documentElement.dir = "rtl"
		}
		else {
			document.documentElement.dir = "ltr"
		}
	}, [selectedLang]);
	const ChangeLang = () => {
		selectedLang === languageEnum.English ?
			changeCurrentLanguage(languageEnum.Arabic) :
			changeCurrentLanguage(languageEnum.English)
	}
	return (
		<div className="bg-home-img min-h-screen bg-cover bg-no-repeat h-[100%] hidden md:flex flex-col w-full items-center">
			<div className='bg-black/[.52] w-full h-full z-10'>
				<div className='w-full flex justify-between items-center xl:pr-[110px] pr-[60px] xl:pl-[85px] pl-[55px] mt-16'>
					<img src={selectedLang === languageEnum.English ? LogoEN : LogoAR} alt='Logo' width={130} height={25} />
					<div className='flex items-center gap-3'>
						<img src={selectedLang === languageEnum.English ? ArabicLang : EnglishLang} alt='lang'
							className='opacity-80 cursor-pointer'
							onClick={ChangeLang} />
						{token &&
							<Button className='w-full border-none rounded-lg justify-center m-0 h-9 p-3' type="submit" onClick={handleLogout}>{t('logout')}</Button>
						}
					</div>
				</div>
				<div className='xl:pr-[110px] pr-[60px] xl:pl-[85px] pl-[55px] flex flex-col gap-5 justify-center mt-[20%]'>
					<img alt='icon' src={Icon} width={28} height={28} />
					<p className='text-sm font-normal leading-9 text-white text-start'>{t('info')}</p>
					<div className='mt-10 flex justify-end'>
						<img src={IconOfHome} alt='Logo' width={33} height={33} className={`${selectedLang === languageEnum.Arabic && 'scale-x-[-1]'}`} />
					</div>
				</div>
			</div>
		</div>
	)
}