import "./app/globals.css"
import { AppRoutes } from "appRoutes";
import './config/i18n.js'
import { Toaster } from "components/ui";
function App() {
  return (
    <div className="App">
      <AppRoutes />
      <Toaster />
    </div>
  );
}
export default App;