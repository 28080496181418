import * as React from "react";
import { cn } from "lib/utils";
import { getCurrentLanguage } from "config/i18n";
import { languageEnum } from "config/enums";

const Input = React.forwardRef(({ className, handleKeyDown, type, placeholder, onChange, value, icon, defaultValue, disabled, ...props }, ref) => {
  const lang = getCurrentLanguage()
  return (
    <div className="relative">
      {icon && <span className={`absolute inset-y-0 flex items-center ${lang === languageEnum.English ? "left-0 pl-3" : "right-0 pr-3"}`}><img src={icon} alt="Icon" /></span>}
      <input
        placeholder={placeholder}
        type={type}
        className={cn(
          "pl-10 flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        {...props}
        autoComplete="new-password"
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </div>
  );
});

Input.displayName = "Input";

export { Input };