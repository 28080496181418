import React from "react"
import { Input } from "components/ui"
import { Label } from "components/ui"
import UploadIcon from "../../assets/img/uploadIcon.svg"
import PdfIcon from "../../assets/img/pdf.svg"
import { getCurrentLanguage } from "config/i18n";
import { languageEnum } from "config/enums";
export function InputFile({ label, styleInput, onChange, data, file, clickUploader, inputRef, disabled, handleDownloadAnswer, answerLink, downloadAnswerRef }) {
	const getShortenedFilename = (url) => {
		if (url) {
			// Extract the part after the last slash and before the query parameters
			const filenameWithParams = url.substring(url.lastIndexOf('/') + 1);
			const [filenameWithExtension] = filenameWithParams.split('?');
			const [filename, extension] = filenameWithExtension.split('.');
			// Get the first 3 letters of the filename
			const shortFilename = filename.substring(0, 4);
			return `${shortFilename}.${extension}`;
		}
	};
	const displayFileName = (value) => {
		if (value.length <= 20) return value
		return value.slice(-20)
	}
	const lang = getCurrentLanguage();
	return (
		<>
			<div className={`${styleInput} && flex flex-row relative bg-[#F8F8F8] rounded-[8px] ${file || data.submitted_answer ? 'rounded-b-none' : null} w-full h-14 items-center mt-5 cursor-pointer`} onClick={clickUploader}>
				<Label className={` absolute ${lang === languageEnum.English ? 'left-6' : 'right-6'}`} htmlFor="file">{label}</Label>
				<img src={UploadIcon} alt="UploadIcon" className={` absolute ${lang === languageEnum.English ? 'right-6' : 'left-6'}`} />
				<Input className='opacity-0 cursor-pointer p-0 hidden' id="file" type="file" onChange={onChange} ref={inputRef} disabled={disabled} />
			</div>
			{file || data.submitted_answer ?
				<div className='p-2 bg-[#F1F1F1] rounded-b-[8px] flex justify-center'>
					<div className='flex flex-row gap-1 items-center'>
						<img src={PdfIcon} alt='PdfIcon' />
						{file ? <span className='text-xs font-light text-[#0048B4] underline'>{displayFileName(file.name)}</span> :
							<a className='text-xs font-light text-[#0048B4] underline' target="_blank" rel="noreferrer" download href={answerLink} onClick={(e) => handleDownloadAnswer(e, data.submitted_answer?.id)} ref={downloadAnswerRef}>{getShortenedFilename(data.submitted_answer?.file_name)}</a>}
					</div>
				</div>
				: null
			}
		</>
	)
}