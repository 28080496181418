import { useNavigate } from 'react-router-dom'
import { Button } from '.'
import { t } from 'i18next'
export const NotFound = () => {
	const navigate = useNavigate()
	return (
		<div className="flex flex-col mt-[20%] items-center gap-10">
			<div className='flex flex-col justify-center items-center gap-6 mt-[10%]'>
				<p className='font-semibold text-8xl text-sky-900'>404</p>
				<p className='font-semibold text-2xl text-sky-900'>Page Not Found</p>
				<p className='font-semibold text-sky-900'>Sorry, the page you are looking for does not exist.</p>
				<Button className="bg-sky-900 hover:bg-sky-900/90" onClick={() => navigate('/')}>{t('return-to-home')}</Button>
			</div>
		</div>
	)
}