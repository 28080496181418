import React from "react";
import { Button } from 'components/ui'
import LogoEN from '../../assets/img/logoEN.svg'
import LogoAR from '../../assets/img/logoAR.svg'
import Welcome from '../../assets/img/welcome.svg'
import EnglishLang from '../../assets/img/englishLang.svg'
import ArabicLang from '../../assets/img/arabicLang.svg'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { changeCurrentLanguage, getCurrentLanguage } from 'config/i18n'
import { languageEnum } from 'config/enums'
import { useEffect } from 'react'
import { localStorageKeys, LocalStorageService } from 'application/services'
import { handleLogout } from 'helpers'
export const Home = () => {
	const navigate = useNavigate()
	const selectedLang = getCurrentLanguage();
	const token = LocalStorageService.get(localStorageKeys.token)
	useEffect(() => {
		if (selectedLang === languageEnum.Arabic) {
			document.documentElement.dir = "rtl"
		}
		else {
			document.documentElement.dir = "ltr"
		}
	}, [selectedLang]);
	const ChangeLang = () => {
		selectedLang === languageEnum.English ?
			changeCurrentLanguage(languageEnum.Arabic) :
			changeCurrentLanguage(languageEnum.English)
	}
	return (
		<div className="w-full flex flex-col items-center justify-center p-6 md:p-10">
			<div className=' w-full flex justify-between md:hidden items-center'>
				<img src={selectedLang === languageEnum.English ? LogoEN : LogoAR} alt='Logo' className='block md:hidden w-24 h-5' />
				<div className='flex items-center gap-3'>
					<img src={selectedLang === languageEnum.English ? ArabicLang : EnglishLang} alt='Lang' className='cursor-pointer' onClick={ChangeLang} />
					{token &&
						<Button className='w-full border-none rounded-lg justify-center m-0 h-9 p-3' type="submit" onClick={handleLogout}>{t('logout')}</Button>
					}
				</div>
			</div>
			<img src={Welcome} alt='WelcomeIMG' width={330} height={330} />
			<p className='font-bold text-base my-3 text-start'>{t('welcome')}</p>
			<p className='text-base text-start'>{t('congratulations')}</p>
			<Button className='w-full md:w-[70%] border rounded-3xl mt-8 justify-center' onClick={() => navigate('/step')}>{t('start')}</Button>
		</div>
	)
}