import React from "react";
import { Button, Form, FormControl, FormField, FormItem, FormMessage, Input, useToast } from 'components/ui'
import PasswordIcon from '../../assets/img/password.svg'
import { ServiceProxy } from 'ServiceProxy';
import { useForm } from 'react-hook-form';
import { setPasswordSchema } from "pages/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { showToastError, showToastTokenError } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import * as Sentry from '@sentry/react';
export const SetPassword = () => {
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { toast } = useToast()
	const form = useForm({
		defaultValues: {
			password: '',
			confirm_password: ''
		},
		resolver: zodResolver(setPasswordSchema)
	})
	const token = new URLSearchParams(window.location.search).get("token")
	const setPassword = (values) => {
		ServiceProxy.AuthService.confirmPassword(values, token)
			.then((res) => {
				setLoading(false)
				navigate('/', { replace: true })
			})
			.catch((error) => {
				setLoading(false)
				if (error.response.data.code === '717') {
					showToastTokenError(toast)
				}
				else {
					showToastError(toast)
				}
				Sentry.captureException(error);
			})
	}
	const onSubmit = (values) => {
		setLoading(true)
		setPassword(values)
	}
	return (
		<div className='w-full h-full p-[12%]'>
			<p className='font-bold text-3xl mb-2 text-start'>{t('welcome-to-amenli')}</p>
			<p className='text-sm text-start text-[#8692A6] mb-5'>{t('password-to-register')}</p>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input className='px-8' placeholder={t('password-placeholder')} icon={PasswordIcon} type='password'
										value={field.value} {...form.register('password')}
										{...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="confirm_password"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Input className='px-8 mt-3' placeholder={t('confirm-password')} icon={PasswordIcon} type='password'
										value={field.value} {...form.register('confirm_password')}
										{...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button className='w-full border rounded-3xl mt-10 mb-3 justify-center' type="submit">{loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />} {t('confirm')}</Button>
				</form>
			</Form>
		</div>
	)
}