import React from "react";
import { t } from "i18next";
import ArrowBack from "../../assets/img/arrowBack.svg"
import EnglishLang from "../../assets/img/englishLang.svg"
import ArabicLang from '../../assets/img/arabicLang.svg'
import { changeCurrentLanguage, getCurrentLanguage } from "config/i18n";
import { languageEnum } from "config/enums";
import { useEffect } from "react";
import { Button } from ".";
import { localStorageKeys, LocalStorageService } from "application/services";
import { handleLogout } from "helpers";
export const Header = ({ onClickBack, currentPage, questionsNumber }) => {
	const selectedLang = getCurrentLanguage();
	const token = LocalStorageService.get(localStorageKeys.token)
	useEffect(() => {
		if (selectedLang === languageEnum.Arabic) {
			document.documentElement.dir = "rtl"
		}
		else {
			document.documentElement.dir = "ltr"
		}
	}, [selectedLang]);
	const ChangeLang = () => {
		selectedLang === languageEnum.English ?
			changeCurrentLanguage(languageEnum.Arabic) :
			changeCurrentLanguage(languageEnum.English)
	}
	return (
		<div className="flex flex-row justify-between items-center py-3 md:py-5 md:pl-14 md:pr-24 px-6 w-full">
			<div className="flex flex-row items-center gap-2 cursor-pointer" onClick={onClickBack}>
				<img src={ArrowBack} alt="ArrowBack" className={`${selectedLang === languageEnum.Arabic && 'scale-x-[-1]'}`} />
				<p className="text-[#8692A6] hidden md:block text-base font-semibold">{t('back')}</p>
			</div>
			{
				window.location.pathname === '/forget-password' ? null :
					<div className="flex flex-col items-center md:items-end gap-1">
						{selectedLang === languageEnum.English ?
							<p className="text-[#BDBDBD] text-sm font-medium">{t('step')} {currentPage}/{questionsNumber}</p> :
							<p className="text-[#BDBDBD] text-sm font-medium">{t('step')} {questionsNumber}/{currentPage}</p>
						}
						{/* <p className="text-[#8692A6] text-sm font-semibold hidden md:block">Upload CR and Tax Card</p> */}
					</div>
			}
			<div className="flex items-center gap-3 md:hidden">
				<img src={selectedLang === languageEnum.English ? ArabicLang : EnglishLang} alt="EnglishLang" className="cursor-pointer" onClick={ChangeLang} />
				{token &&
					<Button className='w-full border-none rounded-lg justify-center m-0 h-9 p-3' type="submit" onClick={handleLogout}>{t('logout')}</Button>
				}
			</div>
		</div>
	)
}